export default {
  name: "TermSheet",
  created() {
    this.$nextTick(function() {
      // 获取数据
      this.getRicList();
      this.WarrantCodeFixation();
    });
  },
  mounted() {
    this.$nextTick(function() {
      // this.$$.navbarAnimation()
    });
  },
  data() {
    return {
      dataArr: [],
      showDataArr: [],
      searchArr: [],
      showsearchArr: [],
      searchArrulF: false,
      inputText: "",
      inputTextlog: "",
      arrT: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取数据
    GetDeployedTermSheet(ric) {
      let arr;
      $.ajax({
        type: "POST",
        dataType: "json",
        url: this.$$.mibb + "GetDeployedTermSheet",
        data: { token: "webkey" },
        success: data => {
          if (data.status == "SUCCESS") {
            $.each(data.text, (iv, cv) => {
              cv.visible = false;
              cv.id = iv;
            });
            this.searchArr = data.text;
            this.showsearchArr = this.searchArr;
            this.dataArr = data.text;
            $.each(this.dataArr, (i, c) => {
              arr = ric.filter(tiem => {
                return (
                  tiem.dsply_nmll.replace(/(^\s*)|(\s*$)/g, "") ==
                  c.dsply_nmll.replace(/(^\s*)|(\s*$)/g, "")
                );
              });
              if (arr.length !== 0) {
                c.ric = arr[0].ric;
              } else {
                c.ric = "";
              }
            });
            console.log(this.dataArr);
          }
        },
        error: function(XMLHttpRequest) {}
      });
    },
    getRicList() {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: this.$$.mibb + "GetRICList",
        async: false,
        data: { token: "webkey" },
        success: data => {
          if (data.status == "SUCCESS") {
            this.GetDeployedTermSheet(data.ric);
            this.$$.tableWidth(
              $("#TermSheet")
                .find(".scrollBar")
                .find("div"),
              $("#TermSheet")
                .find(".table_box")
                .find(".table")
            );
          }
        }
      });
    },
    // 跳转详细页面
    toDetailPage: function(url) {
      window.open(url);
    },
    // Warrant Code 固定
    WarrantCodeFixation: function() {
      $("#TermSheet")
        .find(".table_box")
        .scroll(function() {
          if ($(this).scrollTop() > 0) {
            $(".CodeFixationTableBox").show();
          } else {
            $(".CodeFixationTableBox").hide();
          }
          $(".CodeFixationTableBox").width(
            $("#TermSheet")
              .find(".table_box")
              .outerWidth(true) - 5
          );
        });
    },
    searchArrFun: function(oi, e) {
      console.log(1);
      oi.visible = e.target.checked;
      if (e.target.checked) {
        this.arrT[oi.id] = `${oi.dsply_nmll} (${oi.ticker})`;
        this.showDataArr[oi.id] = oi;
      } else {
        this.arrT[oi.id] = "";
        this.showDataArr[oi.id] = "";
      }
      this.inputText = this.arrT.filter(tiem => tiem !== "");
      this.dataArr = this.showDataArr.filter(tiem => tiem !== "");
    },
    searchArrulop: function(num) {
      if (num !== 0) {
        this.searchArrulF = !this.searchArrulF;
      } else {
        this.searchArrulF = false;
      }
    },
    searchArrSeek: function(e) {
      this.inputText = e.target.value;
      if (e.target.value.length == 0) {
        this.showsearchArr = this.searchArr;
        this.searchArrulF = false;
      } else {
        this.showsearchArr = this.searchArr.filter(function(tiem) {
          return (
            `${tiem.dsply_nmll} (${tiem.ticker})`
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) >= 0 ||
            e.target.value
              .toLowerCase()
              .indexOf(
                `${tiem.dsply_nmll} (${tiem.ticker})`.toLowerCase().dsply_nmll
              ) >= 0
          );
        });
        this.searchArrulF = true;
        console.log(this.showsearchArr);
      }
    },
    inputvanish: function() {
      this.inputTextlog = this.inputText;
      this.inputText = "";
    },
    inputshow: function() {
      this.inputText = this.inputTextlog;
    }
  }
};
